import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { QUERY } from '../gql-operations';

import TextareaAutosize from 'react-textarea-autosize';

import useDeleteMessagesMutation from '../hooks/useDeleteMessagesMutation';
import useTrashMessagesMutation from '../hooks/useTrashMessagesMutation';
import useUpdateMessageMutation from '../hooks/useUpdateMessageMutation';
import useRestoreMessagesMutation from '../hooks/useRestoreMessagesMutation';

import { useQuery } from '@apollo/react-hooks';

import { format } from 'date-fns';

import Hotkeys from 'react-hot-keys';

// import FileOptions from './MessagePreview/FileOptions';

import { MessageOptions, DeleteMessageButton } from './MessagePreview/MessageOptions';

import PluginManager from './MessagePreview/Plugins/PluginManager';

import { AuthContext } from '../util/context';
import useCheckBrowser from '../util/useCheckBrowser';

import { IoMdTrash } from 'react-icons/io';
import {
    NOTE_BACKGROUND,
    PALE_WHITE_2,
    PALE_WHITE_6,
    PRIMARY_COLOR_8,
    RED,
    PALE_WHITE_1,
    WHITE,
    PALE_WHITE_4
} from '../appearance/Colors';
import ReactTooltip from 'react-tooltip';
import UploadPlugin from './MessagePreview/UploadPlugin';

import { FaRegStickyNote } from 'react-icons/fa';

import MyDropzone from '../components/MyDropzone';

import DeleteModal from '../components/Modals/DeleteModal';

import Checkbox from '../components/Folder/Components/Checkbox';
import useNotificationSystem from '../components/Notifications/useNotificationSystem';
import ActionTagButton from './Action/ActionTagButton';
import customAnalytics from '../util/custom-analytics';

function MessagePreviewComponent({ message }) {
    const {
        state: { showDeletedMessages },
        context: { setSelectedFolder, setSelectedMessage }
    } = React.useContext(AuthContext);

    const { showDefaultNotification } = useNotificationSystem();
    const messageContainerRef = React.useRef();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // custom hooks
    const { updateMessage } = useUpdateMessageMutation();
    const [deleteMessages] = useDeleteMessagesMutation();
    const [trashMessages] = useTrashMessagesMutation();
    const { restoreMessagesMutation } = useRestoreMessagesMutation();
    const { Platform, PLATFORMS } = useCheckBrowser();
    const [dropzoneFiles, setDropzoneFiles] = useState(undefined);

    const { data, error, loading } = useQuery(QUERY.GET_MESSAGE, {
        variables: {
            data: {
                id: message.id
            }
        },
        fetchPolicy: 'network-only'
    });

    const formikRef = React.useRef();

    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

    React.useEffect(() => {
        customAnalytics.pageview('/message','MessageScreen');
        customAnalytics.messageview({
            'action': "Open",
            "msg_id": message.id
        }, message)
        return () => {
            customAnalytics.messageview({
                'action': "Close",
                "msg_id": message.id
            }, message)
        };
    }, []);

    React.useEffect(() => {
        // just so the titleRef can rerender
        if (messageContainerRef.current) {
            forceUpdate();
        }
        return () => {
        };
    }, [messageContainerRef.current]);

    // getting the message from apollo cache

    const formatDate = date => {
        let dateFormat = 'dd MMM, HH:mm';
        return format(new Date(date), dateFormat);
    };

    const deletePermanently = () => {
        setSelectedMessage(undefined);
        deleteMessages([message]);
    };

    const trashAll = () => {
        setSelectedMessage(undefined);
        trashMessages([message]);
        showDefaultNotification(message.id, 'Message moved to trash');
    };

    const restoreMessageMutation = () => {
        setSelectedMessage(undefined);
        restoreMessagesMutation([message]);
    };

    const closeMessage = () => {
        formikRef.current.handleSubmit();
        setSelectedMessage(undefined);
    };

    const renderDate = () => {
        const createdAt = new Date(message.createdAt);
        const updatedAt = new Date(message.updatedAt);
        return (
            <div
                style={{
                    color: PALE_WHITE_4,
                    fontSize: 12
                    // lineHeight: '14px'
                }}
            >
                <div>Created: {formatDate(message.createdAt)}</div>
                {createdAt.getTime() !== updatedAt.getTime() ? (
                    <div>Last edited: {formatDate(message.updatedAt)}</div>
                ) : null}
            </div>
        );
    };

    const renderHeader = newMessage => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 16,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: PALE_WHITE_6
                }}
            >
                <div style={{ color: 'white' }}>
                    <div style={{ fontWeight: 500, userSelect: 'none' }}>Message</div>
                    {newMessage.folder ? (
                        <FolderTitle
                            onClick={() => {
                                setSelectedFolder(newMessage.folder);
                                customAnalytics.ui_action({
                                    "action": "Jump to folder",
                                    "screen": "MessageScreen"
                                })
                            }}
                        >
                            {newMessage.folder.title}
                        </FolderTitle>
                    ) : (
                        <div
                            style={{
                                marginTop: 4,
                                fontSize: 12,
                                color: RED
                            }}
                        >
                            Deleted folder
                        </div>
                    )}
                </div>
                <CloseButton onClick={closeMessage}>
                    Close {`${Platform === PLATFORMS.MACINTOSH ? '(⌘.)' : '(Ctr+.)'}`}
                </CloseButton>
            </div>
        );
    };

    if (loading) {
        return null;
    }

    if (data) {
        let newMessage = data.getMessage;

        return (
            <React.Fragment>
                <MyDropzone
                    title="Upload to message"
                    filesSelected={files => {
                        console.log('receiving stuff in the preview from dropzone', files);
                        if (files) {
                            setDropzoneFiles(files);
                        }
                    }}
                    disabled={showDeletedMessages}
                >
                    <Hotkeys
                        keyName="command+Backspace, ctrl+Backspace, command+., ctrl+., delete"
                        onKeyDown={(keyName, e, handle) => {
                            console.log('working', e);
                            console.log('working key', keyName);
                            // console.log('working handle', handle)
                            // setShowFoldersSearch(true);
                            if (
                                keyName === 'command+Backspace' ||
                                keyName === 'ctrl+Backspace' ||
                                keyName === 'delete'
                            ) {
                                e.preventDefault();
                                // closeMessage();
                                // setShowFoldersSearch(true);
                                if (!showDeletedMessages) {
                                    trashAll();
                                } else {
                                    setShowDeleteModal(true);
                                }
                                customAnalytics.ui_action({
                                    "action": "Message Delete",
                                    "shortcut_key": keyName,
                                    "screen": "MessageScreen"
                                })
                            }
                            // if (keyName === 'command+P') {
                            //     e.preventDefault();
                            // }
                            if (keyName === 'command+.' || keyName === 'ctrl+.') {
                                e.preventDefault();
                                closeMessage();
                                customAnalytics.ui_action({
                                    "action": "Message Close",
                                    "shortcut_key": keyName,
                                    "screen": "MessageScreen"
                                })
                            }
                        }}
                        filter={event => {
                            return true;
                        }}
                    >
                        <div
                            style={{
                                overflowY: 'auto',
                                paddingTop: 16,
                                paddingBottom: 12,
                                paddingLeft: 12,
                                paddingRight: 12,
                                height: '100%'
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <MessageContainer ref={messageContainerRef}>
                                    {/* <Button onClick={closeMessage}>Close</Button> */}

                                    <Formik
                                        innerRef={formikRef}
                                        initialValues={{
                                            title: newMessage.title || '',
                                            body: newMessage.body || ''
                                        }}
                                        enableReinitialize
                                        onSubmit={async (values, actions) => {
                                            // updating the message
                                            updateMessage(message, values);
                                        }}
                                        validationSchema={Yup.object().shape({
                                            title: Yup.string(),
                                            body: Yup.string()
                                        })}
                                    >
                                        {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                                            <div>
                                                <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                                                    {renderHeader(newMessage)}
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            marginBottom: 8
                                                        }}
                                                    >
                                                        <Checkbox message={newMessage}></Checkbox>
                                                        <div
                                                            style={{
                                                                padding: '0.5rem',
                                                                backgroundColor: 'white',
                                                                borderRadius: 4,
                                                                flex: 1
                                                            }}
                                                        >
                                                            <TextareaWrapper>
                                                                <TextareaAutosize
                                                                    onKeyDown={event => {
                                                                        if (event.keyCode == 13 && event.shiftKey) {
                                                                            event.stopPropagation();
                                                                        } else if (event.keyCode == 13) {
                                                                            event.preventDefault();
                                                                            handleSubmit();
                                                                        }
                                                                    }}
                                                                    // useCacheForDOMMeasurements
                                                                    style={styles.titleInputStyle}
                                                                    maxRows={5}
                                                                    type="text"
                                                                    name="title"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.title}
                                                                    placeholder="Title"
                                                                ></TextareaAutosize>
                                                            </TextareaWrapper>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            padding: '0.5rem',
                                                            backgroundColor: NOTE_BACKGROUND,
                                                            marginBottom: 8,
                                                            borderRadius: 4,
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <TextareaWrapper>
                                                            <TextareaAutosize
                                                                onKeyDown={event => {
                                                                    if (event.keyCode == 13 && event.shiftKey) {
                                                                        event.stopPropagation();
                                                                    } else if (event.keyCode == 13) {
                                                                        event.preventDefault();
                                                                        handleSubmit();
                                                                    }
                                                                }}
                                                                // useCacheForDOMMeasurements
                                                                style={styles.bodyInputStyle}
                                                                maxRows={24}
                                                                type="text"
                                                                name="body"
                                                                onBlur={handleBlur}
                                                                value={values.body}
                                                                onChange={handleChange}
                                                                placeholder="Note"
                                                            />
                                                        </TextareaWrapper>

                                                        {!values.body ? (
                                                            <FaRegStickyNote
                                                                size={14}
                                                                color={PRIMARY_COLOR_8}
                                                            ></FaRegStickyNote>
                                                        ) : null}
                                                    </div>

                                                    {/* <UploadPlugin message={newMessage}></UploadPlugin> */}
                                                </form>
                                            </div>
                                        )}
                                    </Formik>
                                    <PluginManager
                                        message={newMessage}
                                        files={dropzoneFiles}
                                        clearDropzoneFiles={() => setDropzoneFiles(undefined)}
                                    ></PluginManager>
                                    {/* <FileOptions message={message} /> */}
                                    <div style={{ flex: 1 }}></div>
                                    <MessageOptions
                                        containerWidth={messageContainerRef}
                                        newMessage={newMessage}
                                        style={{ marginBottom: 8 }}
                                        onSuccess={value => {
                                            if (value) {
                                                closeMessage();
                                            }
                                        }}
                                    ></MessageOptions>

                                    {message.deletedAt ? (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {!message.folder.deletedAt ? (
                                                <>
                                                    <ActionTagButton
                                                        label="Restore"
                                                        onClick={() => restoreMessageMutation(true)}
                                                    ></ActionTagButton>
                                                    <div style={{ flex: 1 }}></div>
                                                </>
                                            ) : null}
                                            <ActionTagButton
                                                style={{ color: RED }}
                                                label="Delete permanently"
                                                onClick={() => setShowDeleteModal(true)}
                                            ></ActionTagButton>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-end'
                                            }}
                                        >
                                            {renderDate()}
                                            <div style={{ flex: 1 }}></div>
                                            <a data-tip data-for="deleteIcon">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: 6,
                                                        borderRadius: 2,
                                                        backgroundColor: 'rgba(255,255,255,.2)',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        trashAll();
                                                    }}
                                                >
                                                    <IoMdTrash size={16} color={'rgba(255,255,255,.8)'}></IoMdTrash>
                                                </div>
                                            </a>
                                            <ReactTooltip place="top" type="dark" effect="solid" id="deleteIcon">
                                                <span>{`${Platform === PLATFORMS.MACINTOSH ? '(⌘ + ⌫)' : '(Del)'
                                                    }`}</span>
                                            </ReactTooltip>
                                            {/* <DeleteMessageButton deleteAll={} /> */}
                                        </div>
                                    )}
                                </MessageContainer>
                            </div>
                        </div>
                    </Hotkeys>
                </MyDropzone>
                <DeleteModal
                    isOpen={showDeleteModal}
                    title={`This message will be deleted forever`}
                    description="This message will be deleted and you will not going to be able to access it's content any longer."
                    closeModal={async response => {
                        if (response) {
                            deletePermanently();
                        }
                        setShowDeleteModal(false);
                    }}
                ></DeleteModal>
            </React.Fragment>
        );
    }
}

const styles = {
    titleInputStyle: {
        width: '100%',
        outline: 0,
        padding: 0,
        border: 'none',
        fontSize: 14,
        resize: 'none'
    },
    bodyInputStyle: {
        width: '100%',
        outline: 0,
        padding: 0,
        border: 'none',
        fontSize: 14,
        resize: 'none',
        backgroundColor: NOTE_BACKGROUND
    }
};

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    // height: 100%;
    // overflow-y: auto;
`;

const FolderTitle = styled.div`
    cursor: pointer;
    margin-top: 4px;
    font-size: 12px;
    color: ${PALE_WHITE_6};
    &:hover {
        color: ${WHITE};
    }
`;

const CloseButton = styled.div`
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    color: ${PALE_WHITE_6};
    &:hover {
        color: ${WHITE};
        background-color: ${PALE_WHITE_1};
    }
`;

const TextareaWrapper = styled.div`
    width: 100%;
    // padding: 0.5rem;
    // margin-left: 10px;
    // margin-right: 10px;
    // border-radius: 4px;
    // padding: 0.5rem;
    // background-color: white;
`;

export default MessagePreviewComponent;
