import React, { useState } from 'react';

import getImagePreviews from '../../util/getImagePreviews';

import useS3Request from '../../hooks/useS3Request';
import useCloudinaryRequest from '../../hooks/useCloudinaryRequest';

import FileIcon from '../../appearance/FileIcon';

import { prettySize } from '../../util/fileSizeCalculator';

import { IoMdTrash, IoIosDocument } from 'react-icons/io';

function UploadPlugin({ message }) {
    const [imagesPreview, setImagesPreview] = useState([]);

    const inputFile = React.useRef(null);

    // custom hooks
    const { updateMessageFiles } = useS3Request();
    const { updateMessageImages } = useCloudinaryRequest();

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        const fileListAsArray = Array.from(event.target.files);
        getPreviews(fileListAsArray);
        // this.setState({file}); /// if you want to upload latter
    };

    const getPreviews = async files => {
        let res = await getImagePreviews(files);
        if (res) {
            setImagesPreview(res);
        }
    };

    const clearFileToSend = () => {
        setImagesPreview(undefined);
        inputFile.current.value = '';
    };

    const publishFiles = async () => {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        let imageFiles = [];
        let ordinaryFiles = [];
        filesToSend.forEach(file => {
            if (file.type.includes('image')) {
                imageFiles.push(file);
            } else {
                ordinaryFiles.push(file);
            }
        });
        // somewhere here the successful upload
        clearFileToSend();
        if (ordinaryFiles.length) {
            await updateMessageFiles(ordinaryFiles, message);
        }
        if (imageFiles.length) {
            await updateMessageImages(imageFiles, message);
        }
    };

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const renderPreview = imagesPreview => {
        // console.log('show it sisy', imagesPreview);
        if (imagesPreview) {
            return imagesPreview.map(({ reader, file }, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 16
                        }}
                    >
                        <div style={{ marginRight: 8 }}>
                            {file.type.includes('image') ? (
                                <img src={reader.result} style={{ width: 150, borderRadius: 8 }}></img>
                            ) : (
                                <FileIcon></FileIcon>
                            )}
                        </div>
                        <div style={{ marginRight: 8, flex: 1 }}>
                            <div style={{ marginBottom: 4 }}>{file.name}</div>
                            <div style={{ color: 'rgba(255,255,255,.6)' }}>{prettySize(file.size)}</div>
                        </div>
                        <div onClick={() => removeFromTheList(file)} style={{ cursor: 'pointer' }}>
                            <IoMdTrash size={24} color={'rgba(255,255,255,.4)'}></IoMdTrash>
                        </div>
                    </div>
                );
            });
        }
    };

    const removeFromTheList = file => {
        let previewClone = [...imagesPreview];
        let previewIndex = previewClone.findIndex(listFile => listFile.file === file);

        if (previewIndex > -1) {
            previewClone.splice(previewIndex, 1);
            if (previewClone.length > 0) {
                setImagesPreview(previewClone);
            } else {
                setImagesPreview(undefined);
            }
        }
    };

    return (
        <div style={{ color: 'white' }}>
            <div onClick={onButtonClick}>UploadImage here</div>
            <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
            <div onClick={() => publishFiles()}>Publish</div>
            {renderPreview(imagesPreview)}
        </div>
    );
}

export default UploadPlugin;
