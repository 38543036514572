import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
import { FiCheck, FiArrowUpCircle, FiSmile } from 'react-icons/fi';
import { GREEN, PRIMARY_TEXT, RED, PRIMARY_COLOR } from '../../../../appearance/Colors';

import { Link } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import customAnalytics from '../../../../util/custom-analytics';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const Pricing = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: 'Pricing',
        paragraph: `Luckynote is free but we have also created a special offer for our early adopters`
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <section {...props} className={outerClasses} id="pricing">
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <div style={{ fontSize: 18, lineHeight: '28px' }}>Personal</div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            lineHeight: '34px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        Free
                                    </div>
                                    <div style={{ fontSize: 16, lineHeight: '30px', marginBottom: 16, color: RED }}>
                                        Limited early adopter offer
                                    </div>

                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex'
                                        }}
                                    >
                                        <div style={{ marginRight: 14, marginTop: 2 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px', flex: 1 }}>
                                            100 messages
                                            <span style={{ fontWeight: 600, color: PRIMARY_COLOR }}>*</span>
                                            <div style={{ fontSize: 16, lineHeight: '24px', color: PRIMARY_COLOR }}>
                                                Pay as you go
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: 8
                                        }}
                                    >
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                            <span style={{ fontWeight: '600' }}>2 GB</span> storage
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 18,
                                                lineHeight: '30px',
                                                textDecoration: 'line-through',
                                                marginLeft: 8,
                                                color: RED
                                            }}
                                        >
                                            500 MB
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        {/* <div style={{ flexDirection: 'column' }}> */}
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                            <span style={{ fontWeight: '600' }}>Unlimited</span> file size upload
                                            <span
                                                style={{
                                                    fontSize: 18,
                                                    lineHeight: '30px',
                                                    textDecoration: 'line-through',
                                                    marginLeft: 8,
                                                    color: RED
                                                }}
                                            >
                                                25 MB
                                            </span>
                                        </div>
                                        {/* <div style={{ flexDirection: 'row', display: 'flex' }}> */}
                                        {/* <span
                                            style={{
                                                fontSize: 18,
                                                lineHeight: '30px',
                                                textDecoration: 'line-through',
                                                marginLeft: 8,
                                                color: RED
                                            }}
                                        >
                                            25 MB
                                        </span> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                            Unlimited folders & subfolders
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>Sync across all devices</div>
                                    </div>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <Link
                                        to={isMobile ? 'mobile' : 'register'}
                                        className="button button-primary button-wide-mobile button-md"
                                        style={{ width: '100%', maxWidth: 'none' }}
                                    >
                                        Create Account
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <div style={{ fontSize: 18, lineHeight: '28px' }}>Pro</div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            lineHeight: '34px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        €9.99
                                    </div>
                                    <div style={{ fontSize: 16, lineHeight: '30px', marginBottom: 16 }}>
                                        per month
                                    </div>
                                    {/* <div style={{ fontSize: 18, lineHeight: '30px', marginBottom: 16 }}>Limited early adopter special offer</div> */}
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>Unlimited messages</div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                            <span style={{}}>10 GB</span> storage
                                        </div>
                                        {/* <div
                                            style={{
                                                fontSize: 18,
                                                lineHeight: '30px',
                                                textDecoration: 'line-through',
                                                marginLeft: 8,
                                                color: RED
                                            }}
                                        >
                                            1 GB
                                        </div> */}
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ flexDirection: 'column' }}>
                                            <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                                Unlimited file size upload
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                            Unlimited folders & subfolders
                                        </div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 14 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px' }}>Sync across all devices</div>
                                    </div>
                                    {/* <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            // alignItems: 'center',
                                            marginTop: 8
                                        }}
                                    >
                                        <div style={{ marginRight: 14, marginTop: 2 }}>
                                            <FiSmile size={24} color={GREEN}></FiSmile>
                                        </div>
                                        <div style={{ fontSize: 18, lineHeight: '30px', color: GREEN, flex: 1 }}>
                                            Let us know what's best for you!
                                            <div style={{ fontSize: 16, lineHeight: '24px', color: PRIMARY_COLOR }}>
                                                We would like to hear your opinion and create the best possible deal for
                                                you. Feel free to contact us.
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <a
                                        onClick={() => {
                                            customAnalytics.sign_up_pro();
                                        }}
                                        href="https://buy.stripe.com/28obMG34RarF6Pu3cf"
                                        target="_blank"
                                        className="button button-green button-wide-mobile button-md"
                                        style={{
                                            width: '100%',
                                            maxWidth: 'none'
                                            // '&:hover': {
                                            //     opacity: 0.8,
                                            //     backgroundColor: 'red'
                                            // }
                                        }}
                                    >
                                        Upgrade
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tiles-item reveal-from-bottom" style={{ flexBasis: 824, maxWidth: '100%' }}>
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <div style={{ flexDirection: 'row', alignItems: 'start', display: 'flex' }}>
                                        <div style={{ marginRight: 16 }}>
                                            <FiArrowUpCircle color={GREEN} size={36} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ fontSize: 18, lineHeight: '28px', fontWeight: '600' }}>
                                                Pay as you go
                                            </div>

                                            <div style={{ fontSize: 18, lineHeight: '30px' }}>
                                                Stay in the Free plan by only buying the amount of messages you need.
                                                These are one time purchases.
                                                There is no limit on how many times you can upgrade.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
