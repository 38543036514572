import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { cloudinaryCore } from '../../../util/cloudinary';
import customAnalytics from '../../../util/custom-analytics';

function ImageCarousel({ data, currentMessage, isVisible, closeModal }) {
    // not used
    // const renderCarousel = (message) => {
    // 	if (message.image) {
    // 		return [{ src: cloudinaryCore.url(message.image.url) }];
    // 	} else if (message.file.url) {
    // 		return [{ src: message.file.url }];
    // 	}
    // 	return null;
    // };

    // loop over all the images and show them in carousel
    // without showing the video for now component is in CustomCarouselComponenet.js
    const messagesWithImage = () => {
        const images = data
            .slice(0)
            .filter(message => {
                if (
                    message.image ||
                    (message.file && message.file.type.includes('image'))
                    // (message.file.type === 'image/png' ||
                    //     message.file.type === 'image/jpg' ||
                    //     message.file.type === 'image/jpeg' ||
                    //     message.file.type === 'image/svg' ||
                    //     message.file.type === 'image/svg+xml' ||
                    //     message.file.type === 'image/heic'
                    //     )
                    // || message.file.type === 'video/mp4')
                ) {
                    return message;
                }
            })
            .reverse()
            .map((message, i) => {
                if (message.image) {
                    if (message.image.format !== 'heic') {
                        return {
                            src: cloudinaryCore.url(message.image.url, {
                                quality: 50,
                                fetchFormat: 'auto',
                                crop: 'fit',
                                width: 1080,
                                flags: 'lossy'
                            }),
                            currentIndex: i,
                            messageId: message.id
                        };
                    } else {
                        return {
                            src: cloudinaryCore.url(message.image.public_id, { quality: 50, fetchFormat: 'auto' }),
                            currentIndex: i,
                            messageId: message.id
                        };
                    }
                } else if (message.file) {
                    return {
                        src: message.file.url,
                        currentIndex: i,
                        messageId: message.id
                    };
                }
            });
        return images;
    };

    return (
        <ModalGateway>
            {isVisible ? (
                <Modal
                    onClose={e => {
                        e.stopPropagation();
                        customAnalytics.message_action({
                            'action': 'View Image',
                            "type": "Image"
                        });
                        closeModal();
                    }}
                    styles={{
                        blanket: base => ({
                            ...base,
                            //   backgroundColor: 'rgba(255,255,255,0.85)',
                            zIndex: 9999999
                        }),
                        positioner: base => ({
                            ...base,
                            zIndex: 9999999
                        })
                    }}
                >
                    <Carousel
                        // components={{ View: CustomView }}
                        currentIndex={messagesWithImage().find(obj => obj.messageId === currentMessage.id).currentIndex}
                        // formatters={{}}
                        // styles={{
                        // 	container: base => ({
                        // 		...base,
                        // 		backgroundColor: 'rgba(255,255,255,0.85)'
                        // 	})
                        // }}
                        frameProps={{}}
                        views={messagesWithImage()}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    );
}

export default ImageCarousel;
