import React, { useState, useEffect } from 'react';

import Modal from './Modal';

import SettingsAccountComponent from '../SettingsAccountComponent';
import SettingsAdminComponent from '../SettingsAdminComponent';
import SettingsTabButton from '../SettingsTabButton';

import { IoIosClose } from 'react-icons/io';

import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { QUERY } from '../../gql-operations';

import USER_ROLE from '../../util/userRole';

import { version } from '../../../package.json';
import { PALE_WHITE_6 } from '../../appearance/Colors';
import customAnalytics from '../../util/custom-analytics';
// import SettingsUpgradeComponent from '../SettingsUpgradeComponent';
import Checkout from '../../screens/Checkout/Checkout';

const SETTINGS_TABS = [
    {
        key: 0,
        title: 'Account'
    },
    {
        key: 1,
        title: 'Upgrades'
    },
];

function SettingsModal({ user, isOpen, closeModal, tab }) {
    const [settingsNo, setSettingsNo] = useState(0);
    const { data, loading, error } = useQuery(QUERY.ME, {
        fetchPolicy: 'network-only'
    });

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    useEffect(() => {
        // handleFolderSearchChange('');
        if (isOpen) {
            // customAnalytics.modalview('Settings');
            customAnalytics.pageview('/settings', "Settings");
        }
        if (tab) {
            switchTab(tab)
        }
        return () => {
            // cleanup
        };
    }, [isOpen]);

    const switchTab = (tab) => {
        setSettingsNo(tab)
    }

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                    height: '100%'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            flex: 1,
                            color: 'white',
                            fontSize: 24,
                            fontWeight: 600
                        }}
                    >
                        Settings
                    </div>
                    <CloseButton onClick={closeModal}>
                        <IoIosClose size={32}></IoIosClose>
                    </CloseButton>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '95%'
                    }}
                >
                    <div
                        style={{
                            minWidth: 200,
                            marginTop: 32,
                            marginRight: 50,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <SettingsTabButton
                            onClick={() => setSettingsNo(0)}
                            active={settingsNo === 0}
                            title={SETTINGS_TABS[0].title}
                        ></SettingsTabButton>
                        <SettingsTabButton
                            onClick={() => setSettingsNo(1)}
                            active={settingsNo === 1}
                            title={SETTINGS_TABS[1].title}
                        ></SettingsTabButton>
                        {/* <SettingsTabButton
                            onClick={() => setSettingsNo(2)}
                            active={settingsNo === 2}
                            title={SETTINGS_TABS[2].title}
                        ></SettingsTabButton> */}
                        {/* {user.me.role === USER_ROLE.ADMIN ? (
                            <SettingsTabButton
                                onClick={() => setSettingsNo(1)}
                                active={settingsNo === 1}
                                title={SETTINGS_TABS[1].title}
                            ></SettingsTabButton>
                        ) : null} */}
                        <div style={{ flex: 1 }}></div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: PALE_WHITE_6
                            }}
                        >
                            <span>App version: {version}</span>
                        </div>
                    </div>
                    {SETTINGS_TABS[settingsNo].key === 0 ? (
                        <SettingsAccountComponent user={data} closeModal={closeModal} changeTab={switchTab}></SettingsAccountComponent>
                    ) : null}
                    {SETTINGS_TABS[settingsNo].key === 1 ? (
                        <Checkout hideNavigation></Checkout>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 0,
        overflow: 'hidden',
        // borderColor: 'red',
        // maxWidth: 640,
        minWidth: 1000,
        height: '80%',
        backgroundColor: '#222729'
    }
};

const CloseButton = styled.div`
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

export default SettingsModal;
