import React, { useState, useEffect } from 'react';

import Modal from './Modal';

import styled from 'styled-components';

import { prettySize } from '../../util/fileSizeCalculator';

import { IoMdTrash, IoIosDocument } from 'react-icons/io';

import FileIcon from '../../appearance/FileIcon';

import getImagePreviews from '../../util/getImagePreviews';
import useGetMaxFileSize from '../../util/max_file_size';

import useS3Request from '../../hooks/useS3Request';
import useCloudinaryRequest from '../../hooks/useCloudinaryRequest';
import { PALE_WHITE_4, RED } from '../../appearance/Colors';
import customAnalytics from '../../util/custom-analytics';

function UploadPreviewModal({
    isOpen,
    closeModal,
    currentFolder,
    files,
    successfulUpload,
    currentText,
    uploadProgressPreview,
    autoCheckbox
}) {
    const [textInput, setTextInput] = useState('');
    const [imagesPreview, setImagesPreview] = useState([]);
    // const { data } = useQuery(ME_QUERY, {
    //     fetchPolicy: 'network-only'
    // });

    const { user_max_file_size } = useGetMaxFileSize();

    // custom hooks
    const { sendToS3Files } = useS3Request();
    const { sendCloudinaryFiles } = useCloudinaryRequest();

    useEffect(() => {
        if (files) {
            // console.log('setting files to send', files);
            setTextInput(currentText);
            getPreviews(files);
        }
        return () => {
            console.log('files changed, so cleanup on UploadPreviewModal');
            setImagesPreview(null);
            // cleanup
        };
    }, [files]);

    // if (s3UploadProgress) {
    // 	console.log('you are uploading', s3UploadProgress);
    // 	uploadProgressPreview( s3UploadProgress);
    // }

    const getPreviews = async files => {
        // console.log('incoming files');
        let res = await getImagePreviews(files);
        if (res) {
            setImagesPreview(res);
        }
    };

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const cloudinarySend = async files => {
        let values = {
            title: textInput
        };
        await sendCloudinaryFiles(values, files, currentFolder, autoCheckbox);
        if (files && files.length > 0) {
            customAnalytics.ui_action({
                "action": "Dropzone active",
                "screen": "FolderScreen",
                "type": "Image"
            })
        }
    };

    const s3Send = async files => {
        let values = {
            title: textInput
        };
        await sendToS3Files(values, files, currentFolder, autoCheckbox);
        if (files && files.length > 0) {
            customAnalytics.ui_action({
                "action": "Dropzone active",
                "screen": "FolderScreen",
                "type": "File"
            })
        }
    };

    if (imagesPreview) {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        filesToSend.forEach(file => {
            console.log(file);
            console.log(prettySize(file.size));
        });
    }

    const publishFiles = () => {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        let imageFiles = [];
        let ordinaryFiles = [];
        filesToSend.forEach(file => {
            // Images go to cloudinary only if they are smaller than 10MB, don't do that, it's stupid
            // if (file.type.includes('image') && file.size < 10000000) {
            if (file.type.includes('image')) {
                imageFiles.push(file);
            } else {
                ordinaryFiles.push(file);
            }
        });
        setTextInput('');
        successfulUpload(true);
        s3Send(ordinaryFiles);
        cloudinarySend(imageFiles);
    };

    const handleFolderSearchChange = value => {
        setTextInput(value);
    };

    const removeFromTheList = file => {
        let previewClone = [...imagesPreview];
        let previewIndex = previewClone.findIndex(listFile => listFile.file === file);

        if (previewIndex > -1) {
            previewClone.splice(previewIndex, 1);
            if (previewClone.length > 0) {
                setImagesPreview(previewClone);
            } else {
                setImagesPreview(undefined);
                closeModal(undefined);
            }
        }
    };

    const renderFileSizeResctrictions = file => {
        // if (data.me.role === USER_ROLE.ADMIN) {
        //     return <div style={{ color: 'rgba(255,255,255,.6)' }}>{prettySize(file.size)}</div>;
        // } else if (data.me.planType === USER_TYPE.PRO) {
        //     return <div style={{ color: 'rgba(255,255,255,.6)' }}>{prettySize(file.size)}</div>;
        // } else if (data.me.planType === USER_TYPE.PERSONAL && file.size > user_max_file_size) {
        //     return (
        //         <div style={{ color: 'red' }}>
        //             {prettySize(file.size)} This file is too large to be uploaded for your plan
        //         </div>
        //     );
        // } else
        if (file.size > user_max_file_size) {
            // TODO PLAN
            return (
                <div style={{ color: 'red' }}>
                    {prettySize(file.size)} This file is too large to be uploaded for your plan
                </div>
            );
        } else {
            return <div style={{ color: 'rgba(255,255,255,.6)' }}>{prettySize(file.size)}</div>;
        }
    };

    const renderPreview = imagesPreview => {
        if (imagesPreview) {
            return imagesPreview.map(({ reader, file }, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 16,
                            marginRight: 16,
                            opacity: file.size > user_max_file_size ? 0.8 : 1
                        }}
                    >
                        <div style={{ marginRight: 8 }}>
                            {file.type.includes('image') && !file.type.includes('image/heic') ? (
                                <img src={reader.result} style={{ width: 150, borderRadius: 8 }}></img>
                            ) : (
                                <FileIcon></FileIcon>
                            )}
                        </div>
                        <div style={{ marginRight: 8, flex: 1 }}>
                            <div style={{ marginBottom: 4 }}>{file.name}</div>
                            {renderFileSizeResctrictions(file)}
                        </div>
                        <TrashIcon onClick={() => removeFromTheList(file)}>
                            <IoMdTrash size={24}></IoMdTrash>
                        </TrashIcon>
                    </div>
                );
            });
        }
    };

    if (files) {
        return (
            <Modal
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={() => {
                    closeModal(undefined);
                }}
                style={customStyles}
                // className="Modal"
                overlayClassName="Overlay"
                contentLabel="Example Modal"
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white'
                    }}
                >
                    <div style={{ maxHeight: 400, overflow: 'auto' }}>{renderPreview(imagesPreview)}</div>

                    {/* <div
					style={{
						display: 'flex',
						height: 400,
						flexDirection: 'column',
						overflowY: 'auto'
					}}
				>
					<SelectableList
						currentItem={currentFolder}
						items={showFoldersList()}
						selectedItem={value => {
							// function to move message to selected folder
							resetFoldersSearch();
							closeModal(value);
						}}
					></SelectableList>
				</div> */}
                    <div
                        style={{
                            backgroundColor: 'rgba(255,255,255,.1)',
                            padding: 10,
                            borderRadius: 3,
                            marginTop: 8,
                            marginBottom: 8,
                            color: 'white'
                        }}
                    >
                        <div>
                            <textarea
                                style={{
                                    color: 'rgba(255,255,255,.8)',
                                    backgroundColor: 'transparent',
                                    backgroundColor: 'transparent',
                                    border: 0,
                                    fontSize: 16,
                                    color: 'white',
                                    width: '100%'
                                }}
                                autoFocus
                                onKeyDown={e => {
                                    // console.log('e.', e.key);
                                    // console.log('e.', e.keyCode);
                                    if (e.keyCode === 40 || e.keyCode === 38) {
                                        e.preventDefault();
                                    }
                                    if (e.key === 'Enter' && e.shiftKey) {
                                        e.stopPropagation();
                                    } else if (e.key === 'Enter') {
                                        publishFiles();
                                    }
                                }}
                                value={textInput}
                                onChange={e => handleFolderSearchChange(e.target.value)}
                                type="text"
                                placeholder={`Write in ${currentFolder.title}`}
                            ></textarea>
                        </div>
                    </div>
                    <Button onClick={publishFiles}>Send</Button>
                </div>
            </Modal>
        );
    } else {
        return null;
    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 0,
        // borderColor: 'red',
        // maxWidth: 640,
        minWidth: 600,
        // minHeight: '80%',
        maxHeight: '100%',
        backgroundColor: '#222729'
        // overflow: 'none'
    }
};

const Button = styled.div`
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    // margin-left: 10px;
    // margin-right: 10px;
    // color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

const TrashIcon = styled.div`
    cursor: pointer;
    color: ${PALE_WHITE_4};
    &:hover {
        color: ${RED} !important;
    }
`;

export default UploadPreviewModal;
