import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { MUTATION, QUERY } from '../../gql-operations';
import useRedirectErrors from '../../errors/useRedirectErrors';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { FILE_BLUE, INFO_BLUE_OP_2, DETAIL_TEXT, PLACEHOLDER, WHITE, PALE_WHITE, PALE_WHITE_6, PRIMARY_COLOR, SECONDARY_COLOR } from '../../appearance/Colors';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { IoMdCloseCircle, IoMdArrowUp, IoIosInfinite, IoIosCloud } from "react-icons/io";
import { useMediaQuery } from 'react-responsive';


const Checkout = ({ hideNavigation }) => {
    const stripe = useStripe();
    let history = useHistory();

    const { passingErrors } = useRedirectErrors();

    const [coupon, setCoupon] = useState('');
    const [quantity, setQuantity] = useState(1000);

    const [selectedPurchase, setSetselectedPurchase] = useState(undefined);
    const [checkoutResponse, setCheckoutResponse] = useState(undefined);
    const [subscriptions, setSubscriptions] = useState([]);
    const [oneTimePurchases, setOneTimePurchases] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(undefined);
    const [totalPrice, setTotalPrice] = useState(undefined);
    const [showCoupon, setShowCoupon] = useState(undefined);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    let homeFolder = localStorage.getItem('homeFolder');

    const { data: userData } = useQuery(QUERY.ME);

    const { data: activePlans } = useQuery(QUERY.GET_ACTIVE_PLANS, {
        fetchPolicy: 'network-only',
        onCompleted: res => {
            let activePlans = res.getActivePlans;
            setSubscriptions(activePlans.subscriptions);
            setOneTimePurchases(activePlans.oneTimePurchases);
            if (activePlans.subscriptions.length > 0) {
                setSelectedPlan(activePlans.subscriptions[0])
                makeTotalPrice(activePlans.subscriptions[0])
            }
        }
    });

    const [createCheckoutSessionMutation] = useMutation(MUTATION.CREATE_CHECKOUT_SESSION, {
        onCompleted: res => {
            console.log('createCheckoutSessionMutation res', res)
            setCheckoutResponse(res);
        },
        onError: err => {
            console.log('createCheckoutSessionMutation error', err)
        }
    });

    const renderResponseMessage = () => {
        if (checkoutResponse) {
            console.log('first', checkoutResponse)
            switch (checkoutResponse.createCheckoutSession.__typename) {
                case 'CheckoutSession':
                    console.log('so you gave something in return ', checkoutResponse.createCheckoutSession.id)
                    redirectToCheckout(checkoutResponse.createCheckoutSession.id)
                    setCheckoutResponse(undefined);
                    return true;
                case 'DbStartTransactionError':
                case 'EmailAlreadyTakenError':
                case 'CustomCheckoutSessionError':
                    return <ErrorText>{checkoutResponse.createCheckoutSession.message}</ErrorText>;
                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
        }
        return null;
    };

    const redirectToCheckout = async (sessionId) => {
        const { error } = await stripe.redirectToCheckout({ sessionId: sessionId });

        if (error) {
            console.error("Error redirecting to Stripe:", error.message);
            return <ErrorText>{`Error redirecting to Stripe: ", ${error.message}`}</ErrorText>;
        }
    }


    const createCheckoutSession = async (plan) => {
        if (!stripe) {
            console.error("Stripe has not been initialized.");
            return;
        }

        console.log('this is quantity', quantity)
        // Assuming you have the userId in your local state or from context
        await createCheckoutSessionMutation({
            variables: {
                data: {
                    userId: userData.me.id,
                    planId: plan.id,
                    quantity: parseInt(quantity, 10),
                    coupon: coupon
                }
            },
        });
    };



    const checkIfProUser = () => {
        if (userData && userData.me) {
            return userData.me.planType;
        }
    }

    const renderPrice = (item) => {
        const roundYearlyNumber = Math.trunc(item.price * 12 * 100) / 100;
        if (item.type === 'yearly_one_time') {
            return (
                <>
                    <span style={{ color: PALE_WHITE_6, marginBottom: 4 }}>Once a year</span>
                    <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{roundYearlyNumber} € {isMobile ? '' : '/ year'}</span>
                    {/* <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{roundYearlyNumber} € / year</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>({item.price}/mo)</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>Paid annually</span> */}
                </>
            )
        } else if (item.type === 'yearly') {
            return (
                <>
                    <span style={{ color: PALE_WHITE_6, marginBottom: 4 }}>Yearly</span>
                    <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{item.price} € {isMobile ? '' : '/ month'}</span>
                    {/* <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{roundYearlyNumber} € / year</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>({item.price}/mo)</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>Paid annually</span> */}
                </>
            )

        } else if (item.type === 'monthly') {

            return (
                <>
                    <span style={{ color: PALE_WHITE_6, marginBottom: 4 }}>Monthly</span>
                    <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{item.price} € {isMobile ? '' : '/ month'}</span>
                    {/* <span style={{ color: WHITE, fontSize: 20, fontWeight: '600', marginBottom: 4 }}>{item.price} € / month</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>({roundYearlyNumber}/yo)</span> */}
                    {/* <span style={{ color: PALE_WHITE_6 }}>Paid monthly</span> */}
                </>
            )
        }
    }

    const renderPercent = (plan, percent) => {
        if (percent > 0) {
            if (plan.type === 'yearly' || plan.type === 'yearly_one_time') {
                return (
                    <div style={{
                        backgroundColor: FILE_BLUE,
                        borderRadius: 20,
                        // paddingHorizontal: 8,
                        // paddingVertical: 2,
                        marginBottom: -10,
                        zIndex: 1,
                        position: 'absolute',
                        top: -10,
                        padding: '2px 8px',
                        color: WHITE
                    }}>
                        <span>Save {percent}%</span>
                    </div>
                )
            }
        }
    }

    const makeTotalPrice = (plan) => {
        const roundYearlyNumber = Math.trunc(plan.price * 12 * 100) / 100;
        let title = '';
        if (plan.type === 'yearly_one_time') {
            title = roundYearlyNumber + " € / year";

        } else if (plan.type === 'yearly') {
            title = plan.price + " € / month";


        } else if (plan.type === 'monthly') {
            title = plan.price + " € / month";

        }
        setTotalPrice(title)
    }

    const renderBox = (plan, percent, key) => {
        return (
            <div
                key={plan.id}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    // ...(key % 2 !== 0
                    //     ? { paddingRight: 10, paddingLeft: 10 }
                    //     : { paddingLeft: 10 }),
                    ...(key % 2 !== 0
                        ? { marginLeft: 10 }
                        : {}),
                    ...(key % 3 !== 0
                        ? { marginLeft: 10 }
                        : {}),
                    cursor: "pointer"
                }}
                onClick={() => {
                    setSelectedPlan(plan)
                    makeTotalPrice(plan)
                }}
            >

                <div style={{
                    display: 'flex',
                    minHeight: 100,
                    // backgroundColor: WHITE,
                    backgroundColor: (selectedPlan && selectedPlan.id === plan.id) ? INFO_BLUE_OP_2 : 'transparent',
                    padding: 8,
                    borderRadius: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    borderWidth: 2,
                    borderColor: (selectedPlan && selectedPlan.id === plan.id) ? FILE_BLUE : PLACEHOLDER,
                    borderStyle: 'solid',
                    position: 'relative'
                }}>
                    {renderPercent(plan, percent)}
                    <div style={{
                        // backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        {renderPrice(plan)}
                    </div>
                </div>
            </div>
        )
    }

    const findHighestPrice = (subscriptions) => {
        const highestPrice = subscriptions.reduce((highest, subscription) => {
            const subscriptionPrice = parseFloat(subscription.price); // Convert subscription.price to a number
            if (isNaN(subscriptionPrice)) {
                return highest;
            }
            return Math.max(highest, subscriptionPrice);
        }, -Infinity);
        return highestPrice;
    }

    function calculateSavingsPercentage(regularMonthlyPrice, alternativePrice) {
        return ((regularMonthlyPrice - alternativePrice) / regularMonthlyPrice) * 100;
    }

    const renderSubscriptions = () => {
        if (subscriptions.length > 0) {
            // let percent = Math.ceil((subscriptions[1].price) / (subscriptions[0].price) * 10);
            let maxPrice = findHighestPrice(subscriptions)
            return (
                <>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        // backgroundColor: PALE_WHITE,
                        // paddingHorizontal: 20,
                        // paddingVertical: 16,
                        padding: "20px 0px",

                    }}>
                        {subscriptions.map((plan, key) => {
                            if (plan) {
                                let percent;
                                if (plan.price !== maxPrice) {
                                    percent = Math.ceil((calculateSavingsPercentage(maxPrice ? maxPrice : 0, plan.price)));
                                }
                                return (
                                    renderBox(plan, percent, key)
                                )
                            }
                        })}

                    </div>
                </>
            )
        } else {
            return (
                <ErrorText>No active plans</ErrorText>
            )
        }
    }

    const renderOneTimePurchases = () => {
        if (oneTimePurchases.length > 0) {
            return (
                <div
                    style={{
                        // backgroundColor: 'blue',
                        // paddingHorizontal: 20,
                        // paddingVertical: 16,
                        padding: '20px 0px',
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        // navigation.navigate('PowerUpScreen',
                        //     {
                        //         oneTimePurchases: oneTimePurchases
                        //     }
                        // )
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: PALE_WHITE,
                            borderColor: PLACEHOLDER,
                            borderRadius: 10,
                            borderWidth: 1.5,
                            borderStyle: 'solid',
                            marginBottom: 8,
                            // paddingHorizontal: 12,
                            // paddingVertical: 8,
                            padding: '12px 8px'
                        }}
                    >
                        <span style={{ color: PALE_WHITE_6, flex: 1, fontSize: 16 }}>Power Ups</span>
                        <FiChevronRight color={PALE_WHITE_6} size={16}></FiChevronRight>
                    </div>
                </div>
            )
        }
    }

    const renderTitle = () => {
        return (
            <div style={{
                // paddingHorizontal: 20, 
                // paddingVertical: 16
                // padding: "20px 0px",
                paddingBottom: 20,
                display: 'flex',
                flexDirection: 'column'
            }}>
                {renderNavigation()}
                <span style={{ fontSize: 24, fontWeight: '600', color: WHITE, marginBottom: 4 }}>Luckynote Pro</span>
                <span style={{ fontSize: 16, color: DETAIL_TEXT }}>For power users who want to do even more.</span>
                <div style={{ marginTop: 16, padding: 16, backgroundColor: PALE_WHITE, borderRadius: 8 }}>
                    <div style={{ display: "flex", alignItems: 'center', marginBottom: 16 }}>
                        <div style={{ marginRight: 16 }}>
                            <IoIosInfinite size={24} color={WHITE}></IoIosInfinite>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: 16, fontWeight: '600', color: WHITE, marginBottom: 4 }}>Unlimited messages</span>
                            <span style={{ color: DETAIL_TEXT }}>Save as many messages as you'd like.</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center', marginBottom: 16 }}>
                        <div style={{ marginRight: 16 }}>
                            <IoMdArrowUp size={24} color={WHITE}></IoMdArrowUp>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: 16, fontWeight: '600', color: WHITE, marginBottom: 4 }}>Unlimited file size uploads</span>
                            <span style={{ color: DETAIL_TEXT }}>Upload files of any size to Luckynote.</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <div style={{ marginRight: 16 }}>
                            <IoIosCloud size={24} color={WHITE}></IoIosCloud>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: 16, fontWeight: '600', color: WHITE, marginBottom: 4 }}>10 GB storage</span>
                            <span style={{ color: DETAIL_TEXT }}>Save your favourite pictures, documents and videos.</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderNavigation = () => {
        if (!hideNavigation) {
            return (
                <div style={{ marginTop: 20 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 16,
                            cursor: "pointer",
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <FiChevronLeft color={PALE_WHITE_6} size={16}></FiChevronLeft>
                        <span style={{
                            color: PALE_WHITE_6,
                            flex: 1,
                            fontSize: 14
                        }}>Back to Luckynote</span>
                    </div>
                </div>
            )
        }
    }

    const renderAddCoupon = () => {
        if (subscriptions.length > 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ padding: "20px 16px", alignItems: "center" }}>
                        {showCoupon ?

                            <div style={styles.textInputContainer}>
                                <input
                                    style={styles.textInput}
                                    placeholder="Coupon (optional)"
                                    value={coupon}
                                    onChange={(event) => setCoupon(event.target.value)}
                                ></input>

                                {coupon && coupon.length > 0 ?
                                    <div
                                        onClick={() => setCoupon('')}
                                        style={{ marginRight: 8 }}
                                    >
                                        <IoMdCloseCircle size={20} color={PALE_WHITE_6}></IoMdCloseCircle>
                                    </div>
                                    : null}
                            </div>
                            :
                            <div
                                style={{ cursor: 'pointer', padding: "20px 16px" }}
                                onClick={() => {
                                    setShowCoupon(true)
                                }}>
                                <span style={{ color: PALE_WHITE_6 }}>Add a coupon</span>
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }

    const renderBuyButton = () => {
        if (selectedPlan) {
            return (
                <div
                    style={{
                        display: 'flex',
                        borderRadius: 8,
                        backgroundColor: FILE_BLUE,
                        padding: 16,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        marginTop: 16
                    }}
                    onClick={() => {
                        setCheckoutResponse(undefined)
                        createCheckoutSession(selectedPlan)
                    }}
                >
                    <span style={{ color: WHITE, fontSize: 16, fontWeight: '600' }}>Subscribe to {totalPrice}</span>
                </div >
            )
        } else {
            return null;
        }
    }

    if ((activePlans && !activePlans.getActivePlans) || (userData && !userData.me)) return <div>Loading</div>

    if (homeFolder) {
        return (
            <div style={{ display: 'flex', flex: 1, overflow: 'auto', backgroundColor: PRIMARY_COLOR, justifyContent: 'center' }}>
                <div style={{ flex: 1, maxWidth: 700, padding: isMobile ? "0px 20px" : 0 }}>
                    {renderTitle()}
                    {renderSubscriptions()}
                    {/* {renderOneTimePurchases()} */}
                    {/* <input value={quantity} onChange={(event) => setQuantity(event.target.value)}></input> */}
                    {/* <input value={coupon} onChange={(event) => setCoupon(event.target.value)}></input> */}
                    {renderResponseMessage()}
                    {renderBuyButton()}
                    {renderAddCoupon()}

                </div>
            </div>
        );
    }
    return null;
};

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
    white-space: pre-line;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${WHITE};
    margin-bottom: 24px;
`;

const styles = {
    container: {
        backgroundColor: PRIMARY_COLOR,
        flex: 1
    },
    card: {
        backgroundColor: PALE_WHITE,
        borderColor: PLACEHOLDER,
        borderRadius: 10,
        borderWidth: 1.5,
        marginBottom: 8,
        padding: "12px 8px"
    },
    textInputContainer: {
        cursor: 'pointer',
        display: 'flex',
        backgroundColor: SECONDARY_COLOR,
        flexDirection: 'row',
        borderRadius: 4,
        // maxWidth: "50%",
        alignItems: "center"
    },
    textInput: {
        // paddingRight: 20,
        // paddingLeft: 20,
        padding: 16,
        fontSize: 16,
        flex: 1,
        backgroundColor: 'transparent',
        border: 'none',
        color: WHITE,
        textAlign: 'center',
    },
}

export default Checkout;
