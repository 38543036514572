import React, { useState, useEffect } from 'react';
import CarouselModal from '../../Modals/CarouselModal';

import AudioPlayer from './AudioPlayer/AudioPlayer';
import URLPreviewPlugin from './URLPreviewPlugin';
import ImagePreviewPlugin from './ImagePreviewPlugin';
import VideoPlayerPlugin from './VideoPlayerPlugin';
import FilePreviewPlugin from './FilePreviewPlugin';

import styled from 'styled-components';
import customAnalytics from '../../../util/custom-analytics';

function PluginManager({ message, files, clearDropzoneFiles }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [dropzoneImages, setDropzoneImages] = useState(undefined);
    const [dropzoneFiles, setDropzoneFiles] = useState(undefined);
    // const [urls, setUrls] = useState([]);

    useEffect(() => {
        if (files) {
            decideOnFiles();
        }
        return () => {
            // clearDropzoneFiles();
        };
    }, [files]);

    const decideOnFiles = () => {
        let imageFiles = [];
        let ordinaryFiles = [];
        if (files) {
            files.forEach(file => {
                if (file.type.includes('image')) {
                    imageFiles.push(file);
                } else {
                    ordinaryFiles.push(file);
                }
            });
        }
        if (imageFiles.length) {
            setDropzoneImages(imageFiles);
            customAnalytics.ui_action({
                "action": "Dropzone active",
                "screen": "MessageScreen",
                "type": "Image"
            })
        }
        if (ordinaryFiles.length) {
            setDropzoneFiles(ordinaryFiles);
            customAnalytics.ui_action({
                "action": "Dropzone active",
                "screen": "MessageScreen",
                "type": "File"
            })
        }
        clearDropzoneFiles();
    };

    const renderAudioPlayer = () => {
        if (message.file) {
            if (
                message.file.type.includes('audio')
                // message.file.type === 'audio/wav' ||
                // message.file.type === 'audio/aac' ||
                // message.file.type === 'audio/mpeg' ||
                // message.file.type === 'audio/mp3'
            ) {
                return (
                    <AudioPlayer
                        url={message.file.url}
                        title={message.file.displayName}
                        size={message.file.size}
                        style={{ marginBottom: MARGIN }}
                    />
                );
            }
        }
        return null;
    };

    const renderUrlMeta = () => {
        if (message.urlMeta) {
            return message.urlMeta.map((metaData, index) => {
                return (
                    <URLPreviewPlugin
                        key={index}
                        index={index}
                        message={message}
                        metaData={metaData}
                        style={{ marginBottom: MARGIN }}
                    />
                );
            });
        }
    };

    const renderImagePreview = () => {
        return (
            <ImagePreviewPlugin
                message={message}
                style={{ marginBottom: MARGIN }}
                dropzoneFiles={dropzoneImages}
                // image={message.image}
            />
        );
        // setUrls([message.image.url])
    };

    const renderVideoPreview = () => {
        if (
            message.file && message.file.type.includes('video')
            // (message.file.type === 'video/mp4' ||
            //     message.file.type === 'video/mpeg' ||
            //     message.file.type === 'video/quicktime')
        ) {
            return (
                <VideoPlayerPlugin
                    url={message.file.url}
                    name={message.file.displayName}
                    size={message.file.size}
                    style={{ marginBottom: MARGIN }}
                />
            );
        }
    };

    const renderFilePreview = () => {
        return <FilePreviewPlugin message={message} style={{ marginBottom: MARGIN }} dropzoneFiles={dropzoneFiles} />;
    };

    if (message) {
        return (
            <React.Fragment>
                {renderImagePreview()}
                {renderFilePreview()}
                {renderAudioPlayer()}
                {renderUrlMeta()}
                {renderVideoPreview()}
            </React.Fragment>
        );
    }
    return null;
}

const MARGIN = 8;

const Divider = styled.div`
    margin-bottom: 8px;
`;

export default PluginManager;
